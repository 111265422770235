<template>
  <div class="container">
    <div class="search-wrap">
      <el-form :inline="true" :model="searchForm" class="search-form" @submit.native.prevent>
        <el-select
                v-model="searchForm.keys"
                filterable remote reserve-keyword :placeholder="placeholder"
                :remote-method="getOtherRemote"
                @change="createChart"
                :loading="loading"
                style="width: 500px">
          <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.name + '('+ item.ip +')'"
                  :value="item.name">
          </el-option>
        </el-select>
        <el-button native-type="submit" @click="createChart">生成关系图</el-button>
      </el-form>
    </div>

    <div ref="graphChart" style="width:100%;height: 800px"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';

  const option = {
    legend: [{
      //selectedMode: 'single'
    }],
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',

    series: [
      {
        //name: '关系图',
        type: 'graph',
        //legendHoverLink: false, //启用图例hover联动高亮
        layout: 'force', //采用力引导布局
        data: [],
        links: [],
        categories: [
          {
            "name": "主机"
          },
          {
            "name": "安全设备"
          },
          {
            "name": "网络设备"
          },
          {
            "name": "其他设备"
          },
          {
            "name": "应用"
          },
          {
            "name": "集群"
          },
          {
            "name": "机房机柜"
          }
        ],
        roam: true, //开启缩放和平移
        label: {
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        //连接线标签
        edgeLabel: {
          show: false,
          //formatter: '{b}:{c}'
        },
        force: {
          repulsion: 800,//相距距离
          edgeLength: [150, 200],
          layoutAnimation: true,
          friction: 0.2, //这个参数能减缓节点的移动速度。取值范围 0 到 1。
        },
        lineStyle: {
          color: 'source',
          width: 2,
          curveness: 0
        },
        // scaleLimit: {
        //     min: 0.5,
        //     max: 3
        // },

        labelLayout: {
          hideOverLap: true,
          moveOverlap: 'shiftY'
        },
        tooltip: {
          formatter: '{b}'  //c:数值
        }
      }
    ]
  };

  export default {
    name: "DeviceChart",
    props: {
      type: {
        type: Number
      }
    },
    data() {
      return {
        curId: 1,
        devInfo: {},
        devList: [],
        loading: false,
        searchForm: {
          keys: ''
        },
        placeholder: '请输入任意设备名称或IP'
      }
    },
    mounted() {
      if (this.type === 0) {
        this.createChart();
      }
      if (this.type == 1) {
        this.placeholder = '请输入任意主机设备名称或IP';
      } else if (this.type == 2) {
        this.placeholder = '请输入任意安全设备名称或IP';
      } else if (this.type == 3) {
        this.placeholder = '请输入任意其他设备名称或IP';
      } else if (this.type == 18) {
        this.placeholder = '请输入任意网络设备名称或IP';
      } else {
        this.placeholder = '请输入任意设备名称或IP';
      }
    },
    created() {

    },
    methods: {
      //搜索建议
      getOtherRemote(query) {
        if (query !== '') {
          this.loading = true;
          let params = {
            keys: query,
            page: 1,
            limit: 20
          }
          if (this.type) {
            params.type = this.type;
          }
          this.$get(this.$api.GetDeviceList, params).then((res) => {
            this.loading = false;
            if (res.code === 0) {
              this.devList = res.data.list;
            } else {
              this.$message.error(res.message)
            }
          });
        } else {
          this.devList = [];
        }
      },
      //选择匹配项
      handleSelect() {
        //
      },
      createChart() {
        this.clearChart();
        const myChart = echarts.init(this.$refs.graphChart);
        myChart.showLoading();
        let params = {
          keys: this.searchForm.keys,
          type: this.type
        };
        this.$get(this.$api.GetDeviceChartKeys, params).then((res) => {
          myChart.hideLoading();
          if (res.code === 0) {
            let nodes = res.data.nodes;
            nodes[0].label = {
              show: true,
              fontWeight: 'bold',
              fontSize: '18px'
            }
            option.series[0].data = nodes;
            option.series[0].links = res.data.links;
            myChart.setOption(option);
          } else {
            this.$message.error(res.message);
          }

        });
      },
      clearChart() {
        const myChart = echarts.init(this.$refs.graphChart);
        //myChart.clear();
        option.series[0].data = [];
        myChart.setOption(option);
        myChart.dispose(); //销毁实例
      },
      search() {
        //
      }
    }
  }
</script>

<style scoped lang="less">
  .search-wrap {
    width: 620px;
    margin: 20px auto;
    text-align: center;
  }
</style>
